<template>
  <CRow class="rules-summary">
    <CCol col="12">
      <CCard class="zq--wizard-card">
        <CCardHeader @click="basicIsCollapsed = !basicIsCollapsed">
          <div class="d-flex justify-content-between">
            <strong class="title">{{ texts.createPage.summary.rules.title }}</strong>
            <CLink class="card-header-action btn-minimize">
              <ClCardArrow :is-collapsed="basicIsCollapsed" />
            </CLink>
          </div>
        </CCardHeader>
        <CCollapse :show="basicIsCollapsed" :duration="400">
          <CCardBody class="overflow-auto">
            <ClCardRow :label="texts.createPage.summary.rules.awardLabel">
              <template #content>
                <RulesItem :rulesData="rulesData" v-if="rulesData && Object.keys(rulesData).length !== 0"/>
              </template>
            </ClCardRow>
          </CCardBody>
        </CCollapse>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import ClCardRow from '@/shared/UI/ClCardRow';
import RulesItem from '@/shared/components/RulesItem';
import ClCardArrow from '@/shared/UI/ClCardArrow';
import {achievementsTexts} from "@/config/pageTexts/achievements.json";

export default {
  components: {
    RulesItem,
    ClCardRow,
    ClCardArrow
  },
  props: {
    rulesData: Object
  },
  data() {
    return {
      texts: {
        ...achievementsTexts
      },
      basicIsCollapsed: false
    }
  },
  watch: {
    rulesData: {
      deep: true,
      handler: function (val) {
        console.log('watch rulesData val', val)
      }
    }
  }
}
</script>

<style lang="scss">
.rules-summary {}
</style>